<template>
	<!-- 自定义报表导出进入页 -->
	<div class="container">
		<div class="top">
			<div class="head">
				<div class="title">
					自定义报表导出
				</div><!-- title -->
			</div><!-- head -->
			<div class="section">
				<div class="title">请选择您要输出的报表维度</div>
				<div class="parent_box">
					<div :class="'item ' + (type.p_index === index && 'active')" v-for="(item,index) in type.option"
						:key="index" @click="parent_change(item,index)">
						<div class="left">
							<img class="icon" :src="require('../../assets/img/modify_report/' + item.icon + '.png')">
						</div>
						<div class="right">{{item.title}}</div>
						<img class="checked" src="../../assets/img/gg.png">
					</div><!-- item -->
				</div><!-- parent_box -->
			</div><!-- section -->
			<div class="section">
				<div class="title">请选择导出表格内容</div>
				<div class="type_box">
					<a-radio-group v-model:value="type.c_value" @change="type_change">
						<a-radio v-for="(item,index) in type.option[type.p_index].children" :key="index"
							:value="item">{{item.title}}</a-radio>
					</a-radio-group>
				</div><!-- type_box -->
			</div><!-- section -->
		</div><!-- top -->

		<TableList :rowKey="rowKey" @list_after="list_after" @screen_columns_ok='screen_columns_ok'
			@expandedRowsChange='expandedRowsChange($event)' class="table_list" :slot_table_list="slot_table_list"
			:columns.sync="type.c_value.children" :cate_list.sync="type.c_value.cate_list"
			:get_table_list="this.get_table_list" :form_data_seo="form_data_seo" ref="list" @changeStatus='changeStatus'
			:columns_setting_show="type.c_value.value == 'competing_rate_dap' ? false : true"
			:is_created_get_list="false" :is_page="type.c_value.value == 'competing_rate_dap' ? false : true"
			:default_screen_all_checked="false" :submit_preprocessing="type.c_value.submit_preprocessing"
			:scroll="{x:true}">
			<template v-for="(vo,index) in slot_table_list" :slot="vo" slot-scope="data">
				<span v-if="type.c_value.value == 'competing_rate_dap'">{{getIsNum(data.record[vo])}}</span>
				<span v-if="type.c_value.value == 'competing_rate_user'">
					<span>{{getIsNum(data.record[vo])}}</span>
				</span>
				<div v-if="type.c_value.value == 'custom_cost_contract'" style="width:350px;">
					<div v-for='item in data.record[vo]'>
						<p><span style='color:#000;'>{{(item.type == 'region' ? '位置' : item.type == 'sales' ? '销量' : '数量') + '要求'}}</span></p>
						<p  > {{item.goods_title}}&nbsp;<span v-if="item.num"> x{{ item.num }}</span><span>  {{ item.require }}</span></p>
					</div>
				</div>
				
			</template>
			<template slot="right_btn">
				<a-popconfirm title="您确定要导出该报表吗？" ok-text="确认" cancel-text="取消" @confirm="export_excel">
					<a-button class="a_btn">导出</a-button>
				</a-popconfirm>
			</template>
			<template slot="left_btn">
				<div class="now_dir">{{`已选择：${type.option[type.p_index].title}/${type.c_value.title}`}}</div>
			</template>
		</TableList>
	</div>
</template>

<script>
	import {
		terminalReportSubV2
	} from "@/api/goods";

	import type_data from './json/modify_report.js';
	import TableList from "@/components/TableList";
	import {
		getList,
		getList2,
		exportTable
	} from '@/api/re'
	import {
		getApprovalTypeSelect
	} from "@/api/evection";
	import {
		goodsCate,
		headGoodsCate
	} from "@/api/goods";
	import {
		getType
	} from '@/api/approval';
	import {
		getCostType
	} from "@/api/cost";
	import mapping from './json/mapping_search.json';
	export default {
		components: {
			TableList,
		},
		data() {
			return {
				competing_rate_dap: {
					fixed_selected_list: ["name", "customer_num", "total"],
					fixed_list: [{
							dataIndex: "name",
							title: "名称",
							// fixed: 'left',
							cate_name: "人员字段",
							width: '300px',
						},

						{
							title: "客户数",
							cate_name: "客户字段",
							// fixed: 'left',
							width: '200px',
							dataIndex: "customer_num",

						},
						{
							title: '总数 | 铺市率',
							dataIndex: "total",
							// fixed: 'left',
							width: '200px',
							scopedSlots: {
								customRender: "total"
							}
						},
					],
				},
				competing_rate_user: {
					fixed_selected_list: ["name", "customer_num", "total"],
					fixed_list: [{
							dataIndex: "name",
							title: "名称",
							width: '200px',
							// fixed: 'left',
							cate_name: "人员字段"
						},
						 {
							dataIndex: "uid",
							title: "人员ID",
							width: '150px',
							cate_name: "人员字段"
						},

						{
							dataIndex: "department",
							title: "部门",
							width: '150px',
							cate_name: "人员字段"
						},
						{
							dataIndex: "role",
							title: "职务",
							width: '150px',
							cate_name: "人员字段"
						},
						{
							dataIndex: "rule",
							title: "角色",
							width: '150px',
							cate_name: "人员字段"
						},
						{
							dataIndex: "user_status",
							title: "在职状态",
							width: '150px',
							cate_name: "人员字段"
						},
						{
							dataIndex: "is_leader",
							title: "是否部门领导",
							width: '150px',
							cate_name: "人员字段"
						},
						{
							dataIndex: "is_admin",
							title: "是否管理员",
							width: '150px',
							cate_name: "人员字段"
						},
						{
							title: "客户数",
							cate_name: "客户字段",
							width: '200px',
							// fixed: 'left',
							dataIndex: "customer_num",
						},
						{
							title: '总数 | 铺市率',
							dataIndex: "total",
							width: '200px',
							scopedSlots: {
								customRender: "total"
							}
						},
					],
				},
				customer_sales_volume: {
					fixed_selected_list: ["username", "role_name",'department_name', 'user_status', 'customer_id','customer_name','customer_type','region_type','customer_status','receipt_number','receipt_goods_number'],
					fixed_list: [{
							dataIndex: "username",
							title: "姓名",
							cate_name: "人员字段",
							width: '150px'
						},
						{
							dataIndex: "role_name",
							title: "岗位",
							cate_name: "人员字段",
							width: '150px'
						},
						{
							dataIndex: "department_name",
							title: "所属部门",
							cate_name: "人员字段",
							width: '200px'
						},
						{
							dataIndex: "user_status",
							title: "在职状态",
							cate_name: "人员字段",
							width: '100px'
						},
						{
							dataIndex: "customer_id",
							title: "客户ID",
							cate_name: "客户字段",
							width: '100px'
						},
						{
							dataIndex: "customer_name",
							title: "客户名称",
							cate_name: "客户字段",
							width: '200px'
						},
						{
							dataIndex: "customer_type",
							title: "客户类型",
							cate_name: "客户字段",
							width: '100px'
						},
						{
							dataIndex: "region_type",
							title: "城乡类型",
							cate_name: "客户字段",
							width: '100px'
						},
						{
							dataIndex: "customer_status",
							title: "运营状态",
							cate_name: "客户字段",
							width: '100px'
						},
						{
							dataIndex: "receipt_number",
							title: "回单次数",
							cate_name: "销量字段",
							width: '100px'
						},
						{
							dataIndex: "receipt_goods_number",
							title: "回单件数",
							cate_name: "销量字段",
							width: '100px'
						},
					],
				},
				customer_sales_comparison_cate: {
					fixed_selected_list: ["username", "role_name",'department_name', 'user_status', 'customer_id','customer_name','customer_type','region_type','customer_status'],
					fixed_list: [{
							dataIndex: "username",
							title: "姓名",
							cate_name: "人员字段",
							width: 150
						},
						{
							dataIndex: "role_name",
							title: "岗位",
							cate_name: "人员字段",
							width: 150
						},
						{
							dataIndex: "department_name",
							title: "所属部门",
							cate_name: "人员字段",
							width: 200
						},
						{
							dataIndex: "user_status",
							title: "在职状态",
							cate_name: "人员字段",
							width: 100
						},
						{
							dataIndex: "customer_id",
							title: "客户ID",
							cate_name: "客户字段",
							width: 100
						},
						{
							dataIndex: "customer_name",
							title: "客户名称",
							cate_name: "客户字段",
							width: 200
						},
						{
							dataIndex: "customer_type",
							title: "客户类型",
							cate_name: "客户字段",
							width: 150
						},
						{
							dataIndex: "region_type",
							title: "城乡类型",
							cate_name: "客户字段",
							width: 100
						},
						{
							dataIndex: "customer_status",
							title: "运营状态",
							cate_name: "客户字段",
							width: 100
						},
					],
				},
				goods_list: [],
				cate_list: [],
				columns: [],
				columns_list_temp: [],
				slot_table_list: ['total','require_data'],
				get_table_list: getList,
				form_data_seo: {
					...this.$config.form_data_seo,
					list: [],
				},
				mapping: mapping,
				// 维度相关
				type: {
					option: type_data, // 维度类型（本地数据） 三层
					p_index: 0, // 选中了哪个维度
					c_value: {}, // 选中的表格内容 @return {label,value,children}
				},
				sales_report_key: ["customer_sales_volume","customer_sales_comparison_cate","customer_sales_comparison_goods"],
				// 服务于筛选表单内下拉数据的填充
				api_load: [{
						name: "department", // 需要对modif_report.js中的哪些筛选项表单进行下拉数据的填充
						loaded: false, // 当前类型的下拉数据是否加载完毕，如果为 true 就不会继续调用
						api: this.$method.get_department, // 部门
						key: "treeData", // 决定填充筛选项表单中的哪个属性
						need_load: false, // 当前“表格内容”中是否存在需要获取接口的表单项
					},
					{
						name: "role_id",
						loaded: false,
						api: this.$method.get_role_tree, //岗位职务
						key: "treeData",
						need_load: false,
					},
					// {
					// 	name:"role_id",
					// 	loaded:false,
					// 	api:this.$method.get_role, // 岗位职务
					// 	key:"list",
					// 	need_load:false,
					// },
					{
						name: "customer_level",
						loaded: false,
						api: this.$method.get_level, // 客户等级
						key: "list",
						need_load: false,
					},
					{
						name: "customer_type",
						loaded: false,
						api: this.$method.get_group, // 客户类型
						key: "treeData",
						need_load: false,
					},
					{
						name: "region_type",
						loaded: false,
						api: this.$method.get_RegionType, // 客户类型
						key: "list",
						need_load: false,
					},
					{
						name: "distributor_type",
						loaded: false,
						api: this.$method.get_group, // 经销商类型
						key: "treeData",
						need_load: false,
					},
					{
						name: "approval_type",
						loaded: false,
						api: getApprovalTypeSelect, // 差旅审批类型
						key: "list",
						need_load: false,
					},
					{
						name: "cate_id",
						loaded: false,
						api: goodsCate, // 商品分类
						key: "treeData",
						need_load: false,
					},
					{
						name: "launch_type",
						loaded: false,
						api: getCostType,
						key: "treeData",
						need_load: false,
					},
					// {
					// 	name:"launch_type",
					// 	loaded:false,
					// 	api:getType, // 获取合约类型
					// 	key:"list",
					// 	need_load:false,
					// 	param:{type: 'investment'}, // 需要传入接口的值
					// },
					{
						name: "goods_id",
						loaded: false,
						api: this.$method.get_goods, // 获取品项
						key: "list",
						need_load: false,
					},

				],
			}
		},
		created() {

			this.first_load();
		},
		computed: {
			rowKey() {
				if (this.type.c_value.value == 'custom_visit_user' || this.type.c_value.value == "custom_visit_list" ||
					this.type.c_value.value == "custom_customer_list" || this.type.c_value.value == "custom_stock_report"
				) {
					return 'customer_id'
				} else if (this.type.c_value.value == 'custom_visit_customer' || this.type.c_value.value ==
					"custom_freezer_list") {
					return 'customer_id'
				} else if (this.type.c_value.value == 'custom_trip_report') {
					return 'user_id'
				} else if (this.type.c_value.value == 'custom_trip_order') {
					return 'hotel_id'
				} else if (this.type.c_value.value == 'custom_trip_order') {
					return 'hotel_id'
				} else if (this.type.c_value.value == 'competing_rate_user') {
					return 'phone'
				} else {
					return 'id'
				}
			},

		},
		unmounted() {},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				vm.get_column
			});
		},
		methods: {
			changeStatus(e) {
				if (this.type.p_index == 5) {
					this.res_column(e)
				}
				if (this.type.p_index == 7) {
					this.sales_column(e)
				}
			},
			sales_column(e){
				if (this.type.c_value.value == 'customer_sales_volume') {
					type_data[7].children[0].children = JSON.parse(JSON.stringify(this.customer_sales_volume.fixed_list))
					type_data[7].children[0].selected_field = JSON.parse(JSON.stringify(this.customer_sales_volume.fixed_selected_list))
					this.slot_table_list = []
					this.getGoodsHead(e ? e.goods_id : '')
				} else if (this.type.c_value.value == 'customer_sales_comparison_goods') {
					type_data[7].children[2].children = JSON.parse(JSON.stringify(this.customer_sales_comparison_cate.fixed_list))
					type_data[7].children[2].selected_field = JSON.parse(JSON.stringify(this.customer_sales_comparison_cate.fixed_selected_list))
					this.getSalesHead(e ? e.goods_id : '', e ? e.start_time : '', e ? e.end_time : '')
				} else {
					type_data[7].children[1].children = JSON.parse(JSON.stringify(this.customer_sales_comparison_cate.fixed_list))
					type_data[7].children[1].selected_field = JSON.parse(JSON.stringify(this.customer_sales_comparison_cate.fixed_selected_list))
					this.getCateHead(e ? e.cate_id : '', e ? e.start_time : '', e ? e.end_time : '')
				}
			},
			getGoodsHead(goods_id) {
				this.$method.get_goods_v2({
					data: {
						goods_ids: goods_id ? goods_id : null,
						search_type: 'sales'
					}
				}).then(res => {
					this.goods_list = res.data.list;
					res.data.list.forEach(item => {
						this.slot_table_list.push('goods_' + item.id)
						let obj = {
							title: item.goods_title,
							dataIndex: 'goods_'+item.id,
							cate_name: "none",
							width: 150
						}
						type_data[7].is_more = true
						type_data[7].children[0].children.push(obj)
						type_data[7].children[2].children.push(obj)
						type_data[7].children[2].selected_field.push('goods_'+item.id)
						type_data[7].children[0].selected_field.push('goods_'+item.id)
					})
				})
			},
			getSalesHead(goods_id, start_time, end_time){
				this.$method.get_goods_v2({
					data: {
						goods_ids: goods_id ? goods_id : null,
						start_time: start_time ? start_time : null,
						end_time: end_time ? end_time : null,
						search_type: 'sales_goods'
					}
				}).then(res => {
					this.goods_list = res.data.list;
					this.slot_table_list.push('date_range')
					this.slot_table_list.push('last_year')
					let warp_width = (150 * res.data.list.length) + 100
					let date_range = {
						title: res.data.time_range,
						dataIndex: 'date_range',
						cate_name: "销量字段",
						width: warp_width,
						customHeaderCell: function() {
							return {
								style: {
									borderColor: '#eee',
									borderWidth: '0 1px 1px 1px',
									borderStyle: 'solid',
								},
							};
						},
						children: []
					}
					let last_year = {
						title: '去年同期',
						dataIndex: 'last_year',
						cate_name: "销量字段",
						width: warp_width,
						customHeaderCell: function() {
							return {
								style: {
									borderColor: '#eee',
									borderWidth: '0 1px 1px 1px',
									borderStyle: 'solid',
								},
							};
						},
						children: []
					}
					res.data.list.forEach(item => {
						let item_now = {
							title: item.goods_title,
							cate_name: "销量字段",
							dataIndex: "now_"+item.id,
							width: 150,
							align: "center",
							customHeaderCell: function() {
								return {
									style: {
										borderColor: '#eee',
										borderWidth: '0 1px 1px 1px',
										borderStyle: 'solid',
									},
								};
							},
						}
						date_range.children.push(item_now)
						let item_last = {
							title: item.goods_title,
							cate_name: "销量字段",
							dataIndex: "last_"+item.id,
							width: 150,
							align: "center",
							customHeaderCell: function() {
								return {
									style: {
										borderColor: '#eee',
										borderWidth: '0 1px 1px 1px',
										borderStyle: 'solid',
									},
								};
							},
						}
						last_year.children.push(item_last)
					})
					let subtotal = {
						title: '小计',
						dataIndex: "now_total",
						cate_name: "销量字段",
						width: 100,
						align: "center",
						customHeaderCell: function() {
							return {
								style: {
									borderColor: '#eee',
									borderWidth: '0 1px 1px 1px',
									borderStyle: 'solid',
								},
							};
						},
					}
					date_range.children.push(subtotal)
					let last_total = {
						title: '小计',
						dataIndex: "last_total",
						cate_name: "销量字段",
						width: 100,
						align: "center",
						customHeaderCell: function() {
							return {
								style: {
									borderColor: '#eee',
									borderWidth: '0 1px 1px 1px',
									borderStyle: 'solid',
								},
							};
						},
					}
					last_year.children.push(last_total)
					type_data[7].children[2].children.push(date_range)
					type_data[7].children[2].children.push(last_year)
					type_data[7].children[2].children.push({
						title: "差异",
						dataIndex: 'difference',
						cate_name: '销量字段',
						align: "center",
						width: 100
					})
					type_data[7].is_more = true
					type_data[7].children[2].selected_field.push('date_range')
					type_data[7].children[2].selected_field.push('last_year')
					type_data[7].children[2].selected_field.push('difference')
				})
			},
			getCateHead(cate_id, start_time, end_time){
				headGoodsCate({
					data: {
						cate_id: cate_id ? cate_id : null,
						start_time: start_time ? start_time : null,
						end_time: end_time ? end_time : null
					}
				}).then(res => {
					this.cate_list = res.data.list;
					this.slot_table_list.push('date_range')
					this.slot_table_list.push('last_year')
					let date_range = {
						title: res.data.time_range,
						dataIndex: 'date_range',
						cate_name: "销量字段",
						width: res.data.width,
						customHeaderCell: function() {
							return {
								style: {
									borderColor: '#eee',
									borderWidth: '0 1px 1px 1px',
									borderStyle: 'solid',
								},
							};
						},
						children: []
					}
					let last_year = {
						title: '去年同期',
						dataIndex: 'last_year',
						cate_name: "销量字段",
						width: res.data.width,
						customHeaderCell: function() {
							return {
								style: {
									borderColor: '#eee',
									borderWidth: '0 1px 1px 1px',
									borderStyle: 'solid',
								},
							};
						},
						children: []
					}
					res.data.list.forEach(item => {
						let item_now = {
							title: item.cate_title,
							cate_name: "销量字段",
							dataIndex: "now_"+item.id,
							width: 100,
							align: "center",
							customHeaderCell: function() {
								return {
									style: {
										borderColor: '#eee',
										borderWidth: '0 1px 1px 1px',
										borderStyle: 'solid',
									},
								};
							},
						}
						date_range.children.push(item_now)
						let item_last = {
							title: item.cate_title,
							cate_name: "销量字段",
							dataIndex: "last_"+item.id,
							width: 100,
							align: "center",
							customHeaderCell: function() {
								return {
									style: {
										borderColor: '#eee',
										borderWidth: '0 1px 1px 1px',
										borderStyle: 'solid',
									},
								};
							},
						}
						last_year.children.push(item_last)
					})
					let subtotal = {
						title: '小计',
						dataIndex: "now_total",
						cate_name: "销量字段",
						width: 100,
						align: "center",
						customHeaderCell: function() {
							return {
								style: {
									borderColor: '#eee',
									borderWidth: '0 1px 1px 1px',
									borderStyle: 'solid',
								},
							};
						},
					}
					date_range.children.push(subtotal)
					let last_total = {
						title: '小计',
						dataIndex: "last_total",
						cate_name: "销量字段",
						width: 100,
						align: "center",
						customHeaderCell: function() {
							return {
								style: {
									borderColor: '#eee',
									borderWidth: '0 1px 1px 1px',
									borderStyle: 'solid',
								},
							};
						},
					}
					last_year.children.push(last_total)
					type_data[7].children[1].children.push(date_range)
					type_data[7].children[1].children.push(last_year)
					type_data[7].children[1].children.push({
						title: "差异",
						dataIndex: 'difference',
						cate_name: '销量字段',
						align: "center",
						width: 100
					})
					type_data[7].is_more = true
					type_data[7].children[1].selected_field.push('date_range')
					type_data[7].children[1].selected_field.push('last_year')
					type_data[7].children[1].selected_field.push('difference')
				})
			},
			res_column(e) {
				type_data[5].children[0].children = JSON.parse(JSON.stringify(this.competing_rate_user.fixed_list))
				type_data[5].children[0].selected_field = JSON.parse(JSON.stringify(this.competing_rate_user
					.fixed_selected_list))
				type_data[5].children[1].children = JSON.parse(JSON.stringify(this.competing_rate_dap.fixed_list))
				type_data[5].children[1].selected_field = JSON.parse(JSON.stringify(this.competing_rate_dap
					.fixed_selected_list))
				this.slot_table_list = ['total']
				this.get_column(e ? e.goods_id : '')

			},
			getIsNum(datas, type) {

				if (datas) {
					let text = ''
					text += datas.num ? datas.num : '0'
					text += '|'
					text += datas.ratio ? datas.ratio + '%' : '0%'
					return text
				} else {
					return '0|0%';
				}
			},
			list_after(res) {
				if (this.type.p_index == 5) {
					let list = this.$refs.list.list;
					list = list.map((item, index) => {
						if (item.type == 1) {
							item.children = [];
						}

						return item;
					});
				}

			},
			get_list_key(list, key, index_arr = []) {
				for (let i = 0; i < list.length; i++) {
					if (list[i].id == key) {
						return list[i]
					}

					if (Array.isArray(list[i].children) && list[i].children.length) {
						let value = this.get_list_key(list[i].children, key);
						if (value !== false) {
							return value;
						}
					}
				}

				return false;
			},
			expandedRowsChange(e) {
				if (this.type.p_index == 5) {
					e.forEach(key => {
						let list = JSON.parse(JSON.stringify(this.$refs.list.list));
						let item = this.get_list_key(list, key);
						if (!item.hasOwnProperty('is_req')) {
							terminalReportSubV2({
								data: {
									id: key,
									...this.$refs.list.seo_data
								}
							}).then(res => {

								item.children = [
									...res.data.list.map((vo) => {
										if (vo.type == 1) {
											vo.children = [];
										}

										return vo;
									})

								];
								item.is_req = true;
								this.$refs.list.set_data('list', list);
							})
						}
					});
				}
			},
			screen_columns_ok(e) {
				if (this.type.c_value.value == 'competing_rate_user') {
					this.competing_rate_user.fixed_selected_list = JSON.parse(JSON.stringify(e.checkeds))
					// type_data[5].children[0].selected_field =JSON.parse(JSON.stringify())
					this.res_column(this.$refs.list.seo_data)
				} else if (this.sales_report_key.indexOf(this.type.c_value.value) !== -1) {
					this.sales_column(this.$refs.list.seo_data)
				}
			},
			/* 
			 * 导出表格
			 * 实际上导出并不在本页进行处理
			 * 由于数据体积过大，因此该接口仅作提示作用
			 * 告知用户表格已经进入导出队列，请到“运营管理/导出下载”页面查看导出状况或者进行下载
			 */
			export_excel() {
				exportTable({
					data: {
						field: this.$refs.list.fixed_seo_data.field, // 选择了哪些字段
						export_type: this.$refs.list.fixed_seo_data.export_type, // 选择了哪个维度
						...this.$refs.list.seo_data, // 筛选项表单值
					},
					info: true,
					loading: true
				})
			},
			// 页面第一次加载
			first_load() {
				this.$route.query.range_date1 = undefined;
				console.log(this.sales_report_key, this.$route.query.report_key, this.sales_report_key.indexOf(this.$route.query.report_key))
				// 进来页面时默认选中一个维度（第一个维度下的第一个表格内容）
				if (JSON.stringify(this.$route.query) == '{}' || !this.$route.query.report_key) {
					this.type.c_value = this.type.option[this.type.p_index].children[0];
				} else {
					if (this.$route.query.report_key == "competing_rate_dap") {
						this.res_column(this.$route.query)
					}
					try {
						this.type.option.forEach(item => {
							item.children.forEach(c_item => {
								if (c_item.value == this.$route.query.report_key) {
									this.type.c_value = c_item;
									throw new Error("break");
								}
							});
							this.type.p_index = this.type.p_index + 1;
						});
					} catch (e) {

						if (e.message != "break") throw e;
					}
					if (this.sales_report_key.indexOf(this.$route.query.report_key) !== -1) {
						this.sales_column(this.$route.query)
					}
				}
				this.$nextTick(() => {
					let query = this.get_quert();
					if (query) {
						this.$refs.list.fixed_seo_data = {
							...query
						};
					}

					this.set_list_payload();
					if (query) {
						this.form_data_seo.list.forEach(item => {
							if (!item.options) {
								item.options = {}
							}
							if (item.name == "range_date") {
								if (query["start_time"] && query["end_time"]) {
									item.options.initialValue = [query['start_time'], query['end_time']];
								}
							} else if (item.name == "in_date") {
								if (query["in_start_time"] && query["in_end_time"]) {
									item.options.initialValue = [query['in_start_time'], query[
										'in_end_time']];
								}
							} else if (item.name == "out_date") {
								if (query["out_start_time"] && query["out_end_time"]) {
									item.options.initialValue = [query['out_start_time'], query[
										'out_end_time']];
								}
							} else if (item.name == "address_type,address") {
								item.options1.initialValue = query.address_type;
								item.options2.initialValue = query.address;
							} else if (item.name == "time_type,range_date") {
								item.options.initialValue = [query['start_time'], query['end_time']];
								item.options1.initialValue = query.time_type;
								item.start.options.initialValue = query.start_time
								item.end.options.initialValue = query.end_time
							} else {
								if (query[item.name]) {
									if (query[item.name].indexOf(",") == -1) {
										item.options.initialValue = query[item.name].toString();
									} else {
										item.options.initialValue = query[item.name].split(",");
									}
								}
							}
						});
						this.$refs.list.seo_data = query;
					}
				})
			},
			// 根据当前选中的“表格内容”对筛选表单内的下拉数据进行填充
			options_load() {
				// 当前筛选表单内有哪些需要在本次获取下拉数据
				this.type.c_value.form_list.map(option => {
					this.api_load.map(item => {
						if (option.name === item.name) {
							item.need_load = true; // 如果当前“表格内容”的筛选表单项和 api_load 中对得上就允许它在接下来进行请求
						}
					})
				})

				this.api_load.map(item => {
					/**
					 * 只有该类型的数据没有加载才会请求接口
					 * loaded 和 need_load是有区别的，loaded 优先级远大于 need_load
					 * loaded 代表该接口是否已经加载完毕了
					 * need_load 则是告知当前“表格内容”中是否存在需要请求下拉数据接口的表单项
					 */
					if (!item.loaded && item.need_load) {
						item.api.call(this.$method, {
							data: item.param
						}).then(res => {
							let data = res;
							// 在该判断内的 name 都需要对数据进行额外的转换才能正常使用
							if (item.name === "approval_type") {
								// 差旅审批类型
								data = res.data.list.map(item => {
									return {
										value: item.label,
										key: item.value.toString()
									}
								})
							} else if (item.name === "launch_type") {
								// 获取合约类型
								// data = res.data.list.map(item=>{return {value:item.name,key:item.id.toString()}})
								data = res.data.list
							} else if (item.name === "cate_id"){
								data = res.data.list
							} else if (item.key != "treeData") {
								data = res.map(item => {
									return {
										value: item.value,
										key: item.key.toString()
									}
								})
							}
							// 将获取到的数据填充进筛选项表单的下拉列表中
							this.set_form_item_option({
								name: item.name,
								key: item.key,
								res: data
							});
							item.loaded = true; // 已经加载完毕了，就不需要进行加载了
						});
					}

					item.need_load = false; // 将存在状态重置
				})
			},
			// 填充筛选项表单中的下拉数据
			set_form_item_option({
				name = '',
				key = '',
				res
			}) {
				this.type.option.map(item => {
					item.children.map(child => {
						child.form_list && child.form_list.map(form_item => {
							if (form_item.name === name) {
								form_item[key] = res;
							}
						})
					})
				})
			},
			// 更新当前页面的显示字段并且调取一次列表
			set_list_payload() {

				let list = this.$refs.list;
				// 将筛选项表单中已经填写的值全部清空
				list.seo_data = {};
				// 在UI层面将筛选表单内容清空，避免切换至其他维度时污染同 name 的表单项
				list.$refs.form_seo.form.resetFields();
				// 选中了哪个维度，传给后端
				list.fixed_seo_data.export_type = this.type.c_value.value;
				// 设置“字段设置”弹窗中默认选中哪些选项
				list.fixed_seo_data.field = this.type.c_value.selected_field.toString(); // 传给后端
				list.screen_columns.checked = this.type.c_value.selected_field; // 在“字段设置”弹窗中回显
				// 设置筛选表单中的筛选项
				this.form_data_seo.list = this.type.c_value.form_list;
				this.form_data_seo.list.forEach(item => {
					if (item.options) {
						item.options.initialValue = undefined;
					}
				});

				// 将列表中的“列”与现在设置的“字段设置”同步
				setTimeout(() => {
					list.set_columns();
				}, 1);
				// 该延时器用于覆盖 TableList 组件里 watch 中 columns 对 real_columns 的更新

				list.page = 1; // 切换维度后需要重新从第一页开始
				list.get_list();
				this.options_load(); // 获取筛选表单中的下拉数据
			},
			set_list() {
				let list = this.$refs.list;

				list.screen_columns.checked = this.type.c_value.selected_field; // 在“字段设置”弹窗中回显

				// 将列表中的“列”与现在设置的“字段设置”同步
				setTimeout(() => {
					list.set_columns();
				}, 1);
				// 该延时器用于覆盖 TableList 组件里 watch 中 columns 对 real_columns 的更新

				list.page = 1; // 切换维度后需要重新从第一页开始
				list.get_list();
			},
			// “请选择导出表格内容”发生改变
			type_change(event) {
				if (this.type.p_index == 5) {
					this.res_column()
				} else if (this.type.p_index == 7){
					this.sales_column()
				}
				this.$refs.list.fixed_seo_data = {}
				this.set_list_payload();
			},
			get_quert() {
				if (JSON.stringify(this.$route.query) == '{}' || !this.$route.query.report_key) {
					return false;
				}
				let query = {}
				this.mapping.forEach(item => {
					if (item.report_key == this.$route.query.report_key) {
						item.data.forEach(item_sub => {
							if (this.$route.query[item_sub.old]) {
								if ((item_sub.new == "customer_type" || item_sub.new == "order_status") &&
									this.$route.query[item_sub.old].indexOf(",") != -1 && (this.$refs.list
										.fixed_seo_data.export_type == 'custom_order_list' || this.$refs
										.list.fixed_seo_data.export_type == 'custom_order_distributor')) {
									let level_array = this.$route.query[item_sub.old].split(',');
									query[item_sub.new] = level_array[level_array.length - 1];
								} else {
									query[item_sub.new] = this.$route.query[item_sub.old];
								}
							}
						});
					}
				});
				return query;
			},
			// 铺市率的动态表头
			get_column(goods_id) {
				this.$method.get_goods_v2({
					data: {
						goods_ids: goods_id ? goods_id : null
					}
				}).then(res => {
					this.goods_list = res.data.list;
					res.data.list.forEach(item => {
						this.slot_table_list.push(item.id)
						let obj = {
							title: item.goods_title + ' | 铺市率',
							dataIndex: item.id,
							width: 200,
							cate_name: "none",
							scopedSlots: {
								customRender: item.id
							}
						}
						type_data[5].is_more = true
						type_data[5].children[0].children.push(obj)
						type_data[5].children[1].children.push(obj)
						type_data[5].children[1].selected_field.push(item.id)
						type_data[5].children[0].selected_field.push(item.id)

					})
					this.set_list()
				})
			},
			// “请选择您要输出的报表维度”发生改变
			parent_change(item, index) {
				this.type.p_index = index;

			},
		}
	}
</script>

<style lang="less">
	@import "../../assets/less/app.less";
	@import 'modify_report.less';
</style>