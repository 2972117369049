const list = [{
		icon: "visit",
		title: "拜访维度",
		children: [
			{
				title: "拜访整体情况-按人员",
				value: "custom_visit_user",
				submit_preprocessing: {
					array_to_string: ["department", "role_id"], // 哪些字段值需要由数组转化为逗号隔开
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						options: {},
						list: [{
								key: "-1",
								value: "离职"
							},
							{
								key: "1",
								value: "在职"
							}
						]
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "range_date",
						name: "range_date",
						title: "起止时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					}
				], // 该维度的筛选表单内容
				cate_list: ["人员字段", "拜访字段"], // 字段显示设置中的分类
				selected_field: ["username", "department", "role", "customer_num", "visit_customer",
					"visit_num", "total_visit_customer"
				], // 已经选中的字段
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_num",
						title: "客户总数",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "visit_customer",
						title: "个人拜访客户数",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "not_visit",
						title: "未拜访客户数",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "total_visit_customer",
						title: "总拜访客户数",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "visit_num",
						title: "拜访总次数",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "ratio",
						title: "覆盖率",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "signboard",
						title: "店招",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "box",
						title: "堆箱",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "freezing",
						title: "冰冻化",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "materials",
						title: "广宣物料",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "shelves",
						title: "货架陈列",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "freezing_ratio",
						title: "冰冻化上传率",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "working_num",
						title: "上班天数",
						cate_name: "拜访字段"
					},
				]
			}, /* 拜访整体情况-按人员 */
			{
				title: "拜访整体情况-按客户",
				value: "custom_visit_customer",
				submit_preprocessing: {
					array_to_string: ["customer_type"], // 哪些字段值需要由数组转化为逗号隔开
				},
				form_list: [{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					/* {
						type: "select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						list: [],
					}, */
					{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						options: {},
						list: [{
								key: "-1",
								value: "离职"
							},
							{
								key: "1",
								value: "在职"
							}
						]
					},
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "range_date",
						name: "range_date",
						title: "起止时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					}
				],
				cate_list: ["客户字段", "拜访字段"],
				selected_field: ["customer_name", "customer_status", "customer_type", "bind_num", "visit_num",
					"signboard", "shelves", "materials", "freezing", "box"
				],
				children: [{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "客户状态",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "客户字段"
					},
					{
						dataIndex: "bind_num",
						title: "绑定人员数量",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "visit_num",
						title: "拜访次数",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "signboard",
						title: "店招",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "shelves",
						title: "货架陈列",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "materials",
						title: "广宣物料",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "freezing",
						title: "冰冻化",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "box",
						title: "堆箱",
						cate_name: "拜访字段"
					},
				],
			}, /* 拜访整体情况-按客户 */
			/* 后端表示拜访诶度->拜访日统计难以导出，故隐藏该表格内容 */
			// {
			// 	title:"拜访日统计",
			// 	value:"custom_visit_day",
			// 	form_list:[],
			// 	cate_list:[],
			// 	selected_field:[],
			// 	children:[],
			// },/* 拜访日统计 */
			{
				title: "拜访记录",
				value: "custom_visit_list",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type", "role_id"],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						options: {},
						list: [{
								key: "-1",
								value: "离职"
							},
							{
								key: "1",
								value: "在职"
							}
						]
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "visit_type",
						title: "拜访类型",
						options: {},
						list: [{
								key: "0",
								value: "计划拜访"
							},
							{
								key: "1",
								value: "临时拜访"
							},
							{
								key: "2",
								value: "指定拜访"
							},
							{
								key: "3",
								value: "巡查"
							},
						]
					},
					{
						type: "range_date",
						name: "range_date",
						title: "起止时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					}
				],
				cate_list: ["人员字段", "拜访字段"],
				selected_field: ["username", "department", "role", "customer_name", "customer_type",
					"bind_num", "remark", "photo_num"
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "visit_time",
						title: "拜访时间",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "type",
						title: "拜访类型",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "customer_name",
						title: "拜访客户名称",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "town",
						title: "乡镇名称",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "bind_num",
						title: "绑定人员数量",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "visit_duration",
						title: "拜访时长",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "remark",
						title: "备注",
						cate_name: "拜访字段"
					},
					{
						dataIndex: "photo_num",
						title: "采集照片",
						cate_name: "拜访字段"
					},
				],
			}, /* 拜访记录 */
			{
				title: "客户报表",
				value: "custom_customer_list",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type", "role_id"],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						options: {},
						list: [{
								key: "-1",
								value: "离职"
							},
							{
								key: "1",
								value: "在职"
							}
						]
					},
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "is_line",
						title: "排线状态",
						options: {},
						list: [{
								key: "0",
								value: "未排线"
							},
							{
								key: "1",
								value: "已排线"
							}
						]
					},
					{
						type: "select",
						name: "is_freezer",
						title: "是否绑定冰柜",
						options: {},
						list: [{
								key: "0",
								value: "未绑定"
							},
							{
								key: "1",
								value: "已绑定"
							}
						]
					},
					{
						type: "select",
						name: "order_status",
						title: "是否有未完成订单",
						options: {},
						list: [{
								key: "1",
								value: "是"
							},
							{
								key: "-1",
								value: "否"
							},
						]
					},
					{
						type: "select",
						name: "cost_status",
						title: "是否有未处理合约",
						options: {},
						list: [{
								key: "1",
								value: "是"
							},
							{
								key: "-1",
								value: "否"
							},
						]
					},
					{
						type: "select",
						name: "visit_frequency",
						title: "拜访频率",
						mode: "default",
						options: {},
						list: [{
								key: "1",
								value: "每周1访"
							},
							{
								key: "2",
								value: "每周2访"
							},
							{
								key: "3",
								value: "每周3访"
							},
							{
								key: "4",
								value: "每周4访"
							},
							{
								key: "5",
								value: "每周5访"
							},
							{
								key: "6",
								value: "每周6访"
							},
							{
								key: "7",
								value: "每周7访"
							},
						]
					},
					{
						type: "range_date",
						name: "range_date",
						title: "拜访时间",
						options: {},
						start: {
							name: 'start_time'
						},
						end: {
							name: 'end_time'
						},
					}
				],
				cate_list: ["人员字段", "客户字段"],
				selected_field: ["username", "department", "role", "customer_id", "customer_name",
					"customer_status", "customer_level", "region_type", "customer_type", "address",
					"contact_name", "contact_phone", "bind_num", "line_name", "freezer_num",
					"visit_frequency", "visit_num", "delivery_name", "dealer_name", "create_time",'patrol_num','last_visit'
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "客户状态",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "客户字段"
					},
					{
						dataIndex: "visit_num",
						title: "拜访次数",
						cate_name: "客户字段"
					},
					
					{
						dataIndex: "visit_frequency",
						title: "拜访频率",
						cate_name: "客户字段"
					},
					{
						dataIndex: "last_visit",
						title: "上次拜访时间",
						cate_name: "客户字段"
					},
					{
						dataIndex: "patrol_num",
						title: "巡查次数",
						cate_name: "客户字段"
					},
					{
						dataIndex: "bind_num",
						title: "绑定人员数量",
						cate_name: "客户字段"
					},
					{
						dataIndex: "line_name",
						title: "线路名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "freezer_num",
						title: "冰柜数量",
						cate_name: "客户字段"
					},
					{
						dataIndex: "delivery_name",
						title: "上级配送商",
						cate_name: "客户字段"
					},
					{
						dataIndex: "dealer_name",
						title: "所属经销商",
						cate_name: "客户字段"
					},
					{
						dataIndex: "create_time",
						title: "录入时间",
						cate_name: "客户字段"
					},
					{
						dataIndex: "seal_up",
						title: "封存时间",
						cate_name: "客户字段"
					},
					{
						dataIndex: "seal_user",
						title: "封存人",
						cate_name: "客户字段"
					},

				],
			}, /* 客户报表 */
			{
				title: "库存上报",
				value: "custom_stock_report",
				submit_preprocessing: {
					array_to_string: ["department"],
				},
				form_list: [{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},

					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {},
					},
					{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "select",
						name: "user_status",
						title: "在职状态",
						options: {},
						list: [{
								key: "-1",
								value: "离职"
							},
							{
								key: "1",
								value: "在职"
							}
						]
					},
					{
						type: "range_date",
						name: "range_date",
						title: "创建时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					}
				],
				cate_list: ["人员字段", "客户字段", "库存字段"],
				selected_field: ["username", "department", "customer_name", "address", "visit_id",
					"goods_title",
					"goods_num", "goods_price", "create_time", "compete_goods_title", "compete_goods_num"
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段",
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_department",
						title: "所属区域",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户字段"
					},
					{
						dataIndex: "visit_id",
						title: "拜访ID",
						cate_name: "库存字段"
					},
					{
						dataIndex: "goods_title",
						title: "商品名称",
						cate_name: "库存字段"
					},
					{
						dataIndex: "goods_num",
						title: "商品数量",
						cate_name: "库存字段"
					},
					{
						dataIndex: "goods_price",
						title: "商品单价",
						cate_name: "库存字段"
					},
					{
						dataIndex: "compete_goods_title",
						title: "竞品商品名称",
						cate_name: "库存字段"
					},
					{
						dataIndex: "compete_goods_num",
						title: "竞品商品数量",
						cate_name: "库存字段"
					},
					{
						dataIndex: "create_time",
						title: "创建时间",
						cate_name: "库存字段"
					},
				],
			},
		]
	}, // 拜访维度
	{
		icon: "freeze",
		title: "冰柜维度",
		children: [
			{
			title: "冰柜报表",
			value: "custom_freezer_list",
			submit_preprocessing: {
				array_to_string: ["department", "customer_type", "role_id"],
			},
			form_list: [{
					type: "text",
					name: "keyword",
					title: "关键词",
					options: {}
				},
				{
					type: "text",
					name: "user_search",
					title: "人员信息",
					options: {}
				},
				{
					type: "tree-select",
					name: "department",
					title: "部门",
					options: {},
					treeData: [],
					multiple: true
				},
				{
					type: "tree-select",
					name: "role_id",
					title: "岗位职务",
					options: {},
					treeData: [],
					multiple: true
				},
				// {
				// 	type: "select",
				// 	name: "role_id",
				// 	title: "岗位职务",
				// 	options: {},
				// 	list: [],
				// },
				{
					type: "text",
					name: "customer_search",
					title: "客户信息",
					options: {}
				},
				{
					type: "select",
					name: "customer_status",
					title: "客户状态",
					options: {},
					list: [{
							key: "1",
							value: "运营中"
						},
						{
							key: "-1",
							value: "封存"
						},
					]
				},
				{
					type: "select",
					name: "customer_level",
					title: "客户等级",
					options: {},
					list: []
				},
				{
					type: "tree-select",
					name: "customer_type",
					title: "客户类型",
					options: {},
					treeData: [],
					multiple: true
				},
				{
					type: "text",
					name: "freezer_search",
					title: "冰柜信息",
					options: {}
				},
				{
					type: "select",
					title: "录入方式",
					name: "is_manual",
					options: {},
					list: [{
							key: "",
							value: "全部"
						},
						{
							key: "0",
							value: "扫码录入"
						},
						{
							key: "1",
							value: "手动录入"
						},
					]
				},
				{
					type: "range_date",
					name: "range_date",
					title: "录入时间",
					options: {},
					start: {
						name: "start_time"
					},
					end: {
						name: "end_time"
					},
				},
				{ // 后台终端冰柜的状态
					type: "select",
					title: "冰机状态",
					name: "freezer_status",
					options: {},
					list: [{
							key: "1",
							value: "已绑定"
						},
						{
							key: "0",
							value: "未绑定"
						},
						{
							key: "-1",
							value: "报废"
						},
						{
							key: "-2",
							value: "遗失"
						}
					]
				},
				{
					type: "select",
					title: "当月是否拜访",
					name: "visit_status",
					options: {},
					list: [{
							key: "1",
							value: "是"
						},
						{
							key: "0",
							value: "否"
						}
					]
				},
				{
					type: "select",
					title: "启用状态",
					name: "use_status",
					options: {},
					list: [{
							key: "1",
							value: "启用"
						},
						{
							key: "0",
							value: "禁用"
						},
					]
				},
			],
			cate_list: ["客户字段", "人员字段", "冰柜字段"],
			selected_field: ["customer_name", "customer_status", "customer_type", "username", "department",
				"role", "goods_code", "goods_model", "goods_title", "is_manual", "freezer_status",
				"is_visit"
			],
			children: [{
					dataIndex: "customer_id",
					title: "客户ID",
					cate_name: "客户字段"
				},
				{
					dataIndex: "customer_name",
					title: "客户名称",
					cate_name: "客户字段"
				},
				{
					dataIndex: "customer_status",
					title: "客户状态",
					cate_name: "客户字段"
				},
				{
					dataIndex: "customer_level",
					title: "客户等级",
					cate_name: "客户字段"
				},
				{
					dataIndex: "region_type",
					title: "城乡类型",
					cate_name: "客户字段"
				},
				{
					dataIndex: "customer_type",
					title: "客户类型",
					cate_name: "客户字段"
				},
				{
					dataIndex: "address",
					title: "门店地址",
					cate_name: "客户字段"
				},
				{
					dataIndex: "contact_name",
					title: "联系人",
					cate_name: "客户字段"
				},
				{
					dataIndex: "contact_phone",
					title: "联系方式",
					cate_name: "客户字段"
				},
				{
					dataIndex: "uid",
					title: "人员ID",
					cate_name: "人员字段"
				},
				{
					dataIndex: "username",
					title: "姓名",
					cate_name: "人员字段"
				},
				{
					dataIndex: "sex",
					title: "性别",
					cate_name: "人员字段"
				},
				{
					dataIndex: "department",
					title: "部门",
					cate_name: "人员字段"
				},
				{
					dataIndex: "role",
					title: "职务",
					cate_name: "人员字段"
				},
				{
					dataIndex: "rule",
					title: "角色",
					cate_name: "人员字段"
				},
				{
					dataIndex: "user_status",
					title: "在职状态",
					cate_name: "人员字段"
				},
				{
					dataIndex: "phone",
					title: "账号",
					cate_name: "人员字段"
				},
				{
					dataIndex: "is_leader",
					title: "是否部门领导",
					cate_name: "人员字段"
				},
				{
					dataIndex: "is_admin",
					title: "是否管理员",
					cate_name: "人员字段"
				},
				{
					dataIndex: "goods_code",
					title: "资产编号",
					cate_name: "冰柜字段"
				},
				{
					dataIndex: "goods_model",
					title: "冰机型号",
					cate_name: "冰柜字段"
				},
				{
					dataIndex: "goods_title",
					title: "冰机名称",
					cate_name: "冰柜字段"
				},
				{
					dataIndex: "is_visit",
					title: "当月是否拜访",
					cate_name: "冰柜字段"
				},
				{
					dataIndex: "make_time",
					title: "生产日期",
					cate_name: "冰柜字段"
				},
				{
					dataIndex: "service_mobile",
					title: "售后电话",
					cate_name: "冰柜字段"
				},
				{
					dataIndex: "is_manual",
					title: "录入方式",
					cate_name: "冰柜字段"
				},
				{
					dataIndex: "create_time",
					title: "录入时间",
					cate_name: "冰柜字段"
				},
				{
					dataIndex: "freezer_status",
					title: "冰机状态",
					cate_name: "冰柜字段"
				},
				{
					dataIndex: "use_status",
					title: "启用状态",
					cate_name: "冰柜字段"
				},
			],
		}, 
			{
				title: "冰柜合约报表",
				value: "freezer_contract_list",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type", "role_id", "launch_type"]
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "text",
						name: "contract_title",
						title: "合约名称",
						options: {}
					},
					
					{
					    type: "text",
					    name: "goods_code",
					    title: "资产编号",
					    placeholder: '请输入资产编号',
					    options: {
					
					    }
					},
					{
						type: "select",
						name: "contract_status",
						title: "合约状态",
						list: [{
								key: "-1",
								value: "已过期"
							},
							{
								key: "1",
								value: "期限中"
							},
							{
								key: "-2",
								value: "未开始"
							},
							{
								key: "3",
								value: "已终止"
							},
							{
								key: "2",
								value: "已撤销"
							},
						],
						options: {},
					},
					
					
					{
						type: "range_date",
						name: "range_date",
						title: "创建时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					},
					
				],
				cate_list: ["人员字段", "客户字段", "合约字段"],
				selected_field: ['username','department','role','customer_name','customer_status','contract_title','contract_term','status_name','goods_code','cash','remark'
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "下单客户",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "客户状态",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contract_title",
						title: "合约名称",
						cate_name: "合约字段"
					},
					{
						dataIndex: "contract_term",
						title: "合约期限",
						cate_name: "合约字段"
					},
					{
						dataIndex: "contract_cycle",
						title: "合约周期",
						cate_name: "合约字段"
					},
					{
						dataIndex: "status_name",
						title: "合约状态",
						cate_name: "合约字段"
					},
					{
						dataIndex: "goods_title",
						title: "产品名称",
						cate_name: "合约字段"
					},
					{
						dataIndex: "goods_model",
						title: "产品型号",
						cate_name: "合约字段"
					},
					{
						dataIndex: "goods_code",
						title: "资产编号",
						cate_name: "合约字段"
					},
					{
						dataIndex: "cash",
						title: "押金",
						cate_name: "合约字段"
					},
					{
						dataIndex: "remark",
						title: "其他约定",
						cate_name: "合约字段"
					},
					
				],
			},
		]
	}, // 冰柜维度
	{
		icon: "evection",
		title: "差旅维度",
		children: [{
				title: "差旅报表",
				value: "custom_trip_report",
				submit_preprocessing: {
					array_to_string: ["department", "place_clock", "order_clock", "approval_type", "role_id"],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "select",
						name: "approval_type",
						title: "审批类型",
						mode: "multiple",
						options: {},
						list: []
					},
					{
						type: "select",
						name: "plan_status",
						title: "行程计划状态",
						mode: "default",
						options: {},
						list: [{
								value: "正常",
								key: "0"
							},
							{
								value: "已取消",
								key: "-1"
							},
						]
					},
					{
						type: "select",
						name: "is_book",
						title: "是否预定酒店",
						mode: "default",
						options: {},
						list: [{
								value: "是",
								key: "1"
							},
							{
								value: "否",
								key: "0"
							},
						]
					},
					{
						type: "select",
						name: "place_clock",
						title: "行程打卡",
						mode: "multiple",
						options: {},
						list: [{
								value: "出发已打卡",
								key: "start_on_clock"
							},
							{
								value: "出发未打卡",
								key: "start_not_clock"
							},
							{
								value: "到达已打卡",
								key: "end_on_clock"
							},
							{
								value: "到达未打卡",
								key: "end_not_clock"
							},
						]
					},
					{
						type: "select",
						name: "place_status",
						title: "行程状态",
						mode: "default",
						options: {},
						list: [{
								value: "正常",
								key: "0"
							},
							{
								value: "已取消",
								key: "-1"
							},
						]
					},
					{
						type: "select",
						name: "order_clock",
						title: "酒店打卡",
						mode: "multiple",
						options: {},
						list: [{
								value: "入住已打卡",
								key: "start_on_clock",
							},
							{
								value: "入住未打卡",
								key: "start_not_clock",
							},
							{
								value: "离店已打卡",
								key: "end_on_clock",
							},
							{
								value: "离店未打卡",
								key: "end_not_clock",
							},
						]
					},
					{
						type: "input_group",
						name: "time_type,range_date",
						title: "时间",
						input_type: "range_date",
						list: [{
								value: "计划开始时间",
								key: "plan_start_time"
							},
							{
								value: "计划结束时间",
								key: "plan_end_time"
							},
							{
								value: "入住时间",
								key: "checkinDate"
							},
							{
								value: "离店时间",
								key: "checkoutDate"
							},

						],
						start: {
							name: "start_time",
							options: {},
						},
						end: {
							options: {},
							name: "end_time"
						},
						options1: {},
						options2: {},
						options: {},
					},
					{
						type: "text",
						name: "hotel_name",
						title: "酒店名称",
						options: {},
					},
					{
						type: "input_group",
						name: "address_type,address",
						title: "地点",
						list: [{
								value: "计划出发地",
								key: "plan_start_region"
							},
							{
								value: "计划到达地",
								key: "plan_end_region"
							},
							{
								value: "行程出发地",
								key: "start_place"
							},
							{
								value: "行程到达地",
								key: "end_place"
							},
							{
								value: "酒店地址",
								key: "hotel_address"
							},
						],
						options: {},
						options1: {
							initialValue: ''
						},
						options2: {
							initialValue: ''
						},
					},
					{
						type: "select",
						name: "order_status",
						title: "酒店订单状态",
						mode: "default",
						options: {},
						list: [{
								value: "已入住",
								key: "1"
							},
							{
								value: "已退款",
								key: "-1"
							},

						]
					},
				],
				cate_list: ["审批字段", "行程字段", "酒店字段"],
				selected_field: ["traveler", "traveler_name", "traveler_department_name", "traveler_role_name",
					"approval_type_id", "approval_status", "reason", "plan_start_time", "plan_end_time",
					"plan_start_region", "plan_end_region", "plan_status", "place_status", "start_place",
					"end_place", "place_is_book", "place_start_clock_region", "place_end_clock_region",
					"place_start_clock_time", "place_end_clock_time", "order_status", "hotelName",
					"hotel_address", "checkinDate", "checkoutDate", "totalPrice",
					"order_start_clock_region", "order_end_clock_region", "order_start_clock_time",
					"order_end_clock_time"
				],
				children: [{
						dataIndex: "traveler",
						title: "人员id",
						cate_name: "审批字段"
					},
					{
						dataIndex: "traveler_name",
						title: "人员姓名",
						cate_name: "审批字段"
					},
					{
						dataIndex: "traveler_department_name",
						title: "部门",
						cate_name: "审批字段"
					},
					{
						dataIndex: "traveler_role_name",
						title: "岗位",
						cate_name: "审批字段"
					},
					{
						dataIndex: "approval_type_id",
						title: "审批类型",
						cate_name: "审批字段"
					},
					{
						dataIndex: "approval_status",
						title: "审批状态",
						cate_name: "审批字段"
					},
					{
						dataIndex: "reason",
						title: "出差事由",
						cate_name: "审批字段"
					},
					{
						dataIndex: "plan_start_time",
						title: "计划开始时间",
						cate_name: "审批字段"
					},
					{
						dataIndex: "plan_end_time",
						title: "计划结束时间",
						cate_name: "审批字段"
					},
					{
						dataIndex: "plan_start_region",
						title: "计划出发地",
						cate_name: "审批字段"
					},
					{
						dataIndex: "plan_end_region",
						title: "计划到达地",
						cate_name: "审批字段"
					},
					{
						dataIndex: "plan_status",
						title: "计划状态",
						cate_name: "审批字段"
					},
					{
						dataIndex: "place_status",
						title: "行程状态",
						cate_name: "行程字段"
					},
					{
						dataIndex: "start_place",
						title: "行程出发地",
						cate_name: "行程字段"
					},
					{
						dataIndex: "place_start_clock_time",
						title: "出发打卡时间",
						cate_name: "行程字段"
					},
					{
						dataIndex: "place_start_clock_region",
						title: "出发打卡地点",
						cate_name: "行程字段"
					},
					{
						dataIndex: "end_place",
						title: "行程到达地",
						cate_name: "行程字段"
					},
					{
						dataIndex: "place_end_clock_time",
						title: "到达打卡时间",
						cate_name: "行程字段"
					},
					{
						dataIndex: "place_end_clock_region",
						title: "到达打卡地点",
						cate_name: "行程字段"
					},
					{
						dataIndex: "place_is_book",
						title: "是否入住酒店",
						cate_name: "行程字段"
					},
					{
						dataIndex: "order_status",
						title: "订单状态",
						cate_name: "酒店字段"
					},
					{
						dataIndex: "hotelName",
						title: "酒店名称",
						cate_name: "酒店字段"
					},
					{
						dataIndex: "hotel_address",
						title: "酒店地址",
						cate_name: "酒店字段"
					},
					{
						dataIndex: "checkinDate",
						title: "入住时间",
						cate_name: "酒店字段"
					},
					{
						dataIndex: "checkoutDate",
						title: "离店时间",
						cate_name: "酒店字段"
					},
					{
						dataIndex: "totalPrice",
						title: "结算金额",
						cate_name: "酒店字段"
					},
					{
						dataIndex: "order_start_clock_region",
						title: "入住打卡地点",
						cate_name: "酒店字段"
					},
					{
						dataIndex: "order_end_clock_region",
						title: "离店打卡地点",
						cate_name: "酒店字段"
					},
					{
						dataIndex: "order_start_clock_time",
						title: "入住打卡时间",
						cate_name: "酒店字段"
					},
					{
						dataIndex: "order_end_clock_time",
						title: "离店打卡时间",
						cate_name: "酒店字段"
					},
				],
			},
			{
				title: "酒店订单报表",
				value: "custom_trip_order",
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "text",
						name: "order_no",
						title: "订单编号",
					},
					{
						type: "text",
						name: "hotel_name",
						title: "酒店名称"
					},
					{
						type: "select",
						name: "order_status",
						title: "订单状态",
						mode: "default",
						options: {},
						list: [{
								value: "订单处理中",
								key: "0"
							},
							{
								value: "创建订单",
								key: "10"
							},
							{
								value: "预定中",
								key: "20"
							},
							{
								value: "预定成功",
								key: "21"
							},
							{
								value: "预定失败",
								key: "22"
							},
							{
								value: "取消中",
								key: "30"
							},
							{
								value: "取消成功",
								key: "31"
							},
							{
								value: "取消失败",
								key: "32"
							},
							{
								value: "已消费退款",
								key: "40"
							},
							{
								value: "已入住",
								key: "50"
							},
						]
					},
					{
						type: "range_date",
						name: "in_date",
						title: "入住时间",
						options: {},
						start: {
							name: "in_start_time"
						},
						end: {
							name: "in_end_time"
						},
					},
					{
						type: "range_date",
						name: "out_date",
						title: "离店时间",
						options: {},
						start: {
							name: "out_start_time"
						},
						end: {
							name: "out_end_time"
						},
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					}
				],
				cate_list: ["人员字段", "订单字段"],
				selected_field: ["person_name", "person_department", "person_role", "order_no", "order_type",
					"order_status", "hotel_id", "hotel_name", "hotel_address", "contact_phone",
					"create_time", "checkinDate", "checkoutDate", "goodsName", "roomNum", "totalPrice"
				],
				children: [{
						dataIndex: "person_name",
						title: "入住人姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "person_sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "person_department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "person_role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "person_rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "person_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "person_phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "person_is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "person_is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "order_no",
						title: "订单编号",
						cate_name: "订单字段"
					},
					{
						dataIndex: "order_type",
						title: "订单类型",
						cate_name: "订单字段"
					},
					{
						dataIndex: "order_status",
						title: "订单状态",
						cate_name: "订单字段"
					},
					{
						dataIndex: "hotel_id",
						title: "酒店id",
						cate_name: "订单字段"
					},
					{
						dataIndex: "hotel_name",
						title: "酒店名称",
						cate_name: "订单字段"
					},
					{
						dataIndex: "hotel_address",
						title: "酒店地址",
						cate_name: "订单字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系电话",
						cate_name: "订单字段"
					},
					{
						dataIndex: "create_time",
						title: "下单时间",
						cate_name: "订单字段"
					},
					{
						dataIndex: "checkinDate",
						title: "入住时间",
						cate_name: "订单字段"
					},
					{
						dataIndex: "checkoutDate",
						title: "离店时间",
						cate_name: "订单字段"
					},
					{
						dataIndex: "goodsName",
						title: "入住房型",
						cate_name: "订单字段"
					},
					{
						dataIndex: "roomNum",
						title: "数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "totalPrice",
						title: "订单总额",
						cate_name: "订单字段"
					},
				],
			},
		]
	}, // 差旅维度
	{
		icon: "order",
		title: "订单维度",
		children: [{
				title: "订单报表",
				value: "custom_order_list",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type", "order_status", "role_id"],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "distributor_type",
						title: "去重",
						options: {},
						treeData: [],
						list: [{
								key: "customer",
								value: "终端"
							},
							{
								key: "wholesale",
								value: "分销"
							}
						]
					},
					{
						type: "select",
						name: "goods_id",
						title: "品项",
						options: {},
						list: []
					},
					{
						type: "text",
						name: "order_no",
						title: "订单编号",
					},
					{
						type: "select",
						name: "order_type",
						title: "订单类型",
						options: {},
						list: [{
								key: "1",
								value: "现结订单"
							},
							{
								key: "2",
								value: "预付款订单"
							},
						]
					},
					{
						type: "text",
						name: "distributor_search",
						title: "上级配送商",
						placeholder: "配送商ID/名称"
					},
					{
						type: "select",
						title: "订单状态",
						name: "order_status",
						options: {},
						mode: 'multiple',
						list: [{
								key: "0",
								value: "待出库"
							},
							{
								key: "1",
								value: "出库中"
							},
							{
								key: "2",
								value: "已完成"
							},
							{
								key: "-1",
								value: "已取消"
							}
						],
					},
					{
						type: "range_date",
						name: "range_date",
						title: "订单创建时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					},
					/* 提货结束时间筛选项不要了 */
				],
				cate_list: ["人员字段", "客户字段", "订单字段"],
				selected_field: ["username", "department", "role", "customer_name", "customer_status",
					"customer_type", "order_no", "order_type", "distributor_name", "product_name",
					"product_num", "product_spec", "gift_name", "gift_num", "gift_spec", "promotion_name",
					"promotion_num", "promotion_spec", "reward_name", "reward_num", "reward_spec",
					"pay_price", "order_status", "checkout_num", "create_time", "end_time", "remarks",
					"dealer_name"
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "下单人",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_id",
						title: "客户id",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "下单客户",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "客户状态",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "客户字段"
					},
					{
						dataIndex: "order_no",
						title: "订单编号",
						cate_name: "订单字段"
					},
					{
						dataIndex: "order_type",
						title: "订单类型",
						cate_name: "订单字段"
					},
					{
						dataIndex: "distributor_name",
						title: "配送商",
						cate_name: "订单字段"
					},
					{
						dataIndex: "product_name",
						title: "下单本品",
						cate_name: "订单字段"
					},
					{
						dataIndex: "product_num",
						title: "本品数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "product_spec",
						title: "本品规格",
						cate_name: "订单字段"
					},
					{
						dataIndex: "gift_name",
						title: "搭赠品项",
						cate_name: "订单字段"
					},
					{
						dataIndex: "gift_num",
						title: "搭赠数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "gift_spec",
						title: "搭赠规格",
						cate_name: "订单字段"
					},
					{
						dataIndex: "promotion_name",
						title: "促销品项",
						cate_name: "订单字段"
					},
					{
						dataIndex: "promotion_num",
						title: "促销数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "promotion_spec",
						title: "促销规格",
						cate_name: "订单字段"
					},
					{
						dataIndex: "reward_name",
						title: "提货奖励品项",
						cate_name: "订单字段"
					},
					{
						dataIndex: "reward_num",
						title: "提货奖励数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "reward_spec",
						title: "提货奖励规格",
						cate_name: "订单字段"
					},
					{
						dataIndex: "pay_price",
						title: "订单金额",
						cate_name: "订单字段"
					},
					{
						dataIndex: "order_status",
						title: "订单状态",
						cate_name: "订单字段"
					},
					{
						dataIndex: "checkout_num",
						title: "出库单数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "create_time",
						title: "订单创建时间",
						cate_name: "订单字段"
					},
					{
						dataIndex: "end_time",
						title: "提货结束时间",
						cate_name: "订单字段"
					},
					{
						dataIndex: "remarks",
						title: "备注",
						cate_name: "订单字段"
					},
					{
						dataIndex: "dealer_name",
						title: "所属经销商",
						cate_name: "订单字段"
					},

				],
			},
			{
				title: "订单-按配送商",
				value: "custom_order_distributor",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type"],
				},
				form_list: [{
						type: "text",
						name: "distributor_search",
						title: "配送商信息",
						placeholder: "配送商ID/名称/账号"
					},
					{
						type: "text",
						name: "user_search",
						title: "人员信息"
					},
					{
						type: "tree-select",
						name: "department",
						title: "所属区域",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "distributor_status",
						title: "配送商状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "select",
						name: "distributor_type",
						title: "去重",
						options: {},
						treeData: [],
						list: [{
								key: "customer",
								value: "终端"
							},
							{
								key: "wholesale",
								value: "分销"
							}
						]
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "text",
						name: "order_no",
						title: "订单编号"
					},
					{
						type: "range_date",
						name: "range_date",
						title: "起止时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					}
				],
				cate_list: ["配送商字段", "订单字段"],
				selected_field: ["distributor_name", "distributor_status", "distributor_type", "goods_title",
					"goods_type", "goods_num", "goods_price", "order_num", "sale_num", "gift_num",
					"reward_num",
					"promotion_num", "total_num", "pay_price"
				],
				children: [{
						dataIndex: "distributor_id",
						title: "配送商ID",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "distributor_name",
						title: "配送商名称",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "distributor_department",
						title: "所属区域",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "distributor_status",
						title: "配送商状态",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "distributor_level",
						title: "配送商等级",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "distributor_type",
						title: "配送商类型",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "配送商字段"
					},
					{
						dataIndex: "goods_title",
						title: "品项名称",
						cate_name: "订单字段"
					},
					{
						dataIndex: "goods_type",
						title: "品项类型",
						cate_name: "订单字段"
					},
					{
						dataIndex: "goods_num",
						title: "品项数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "goods_price",
						title: "品项总价",
						cate_name: "订单字段"
					},
					{
						dataIndex: "order_num",
						title: "订单数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "sale_num",
						title: "销售数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "gift_num",
						title: "折扣数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "promotion_num",
						title: "促销数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "reward_num",
						title: "提货奖励数量",
						cate_name: "订单字段"
					},
					{
						dataIndex: "total_num",
						title: "数量合计",
						cate_name: "订单字段"
					},
					{
						dataIndex: "pay_price",
						title: "总金额",
						cate_name: "订单字段"
					},
				],
			},
			{
				title: "订单-按品项",
				value: "custom_order_goods",
				submit_preprocessing: {
					array_to_string: [],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息"
					},
					{
						type: "tree-select",
						name: "department",
						title: "所属区域",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "text",
						name: "distributor_search",
						title: "配送商信息",
						placeholder: "配送商ID/名称/账号"
					},
					{
						type: "select",
						name: "distributor_type",
						title: "去重",
						options: {},
						treeData: [],
						list: [{
								key: "customer",
								value: "终端"
							},
							{
								key: "wholesale",
								value: "分销"
							}
						]
					},
					{
						type: "text",
						name: "order_no",
						title: "订单编号"
					},
					{
						type: "select",
						name: "goods_id",
						title: "品项",
						options: {},
					},
					{
						type: "text",
						name: "goods_search",
						title: "商品名称",
					},
					{
						type: "range_date",
						name: "range_date",
						title: "起止时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					}
				],
				cate_list: [],
				selected_field: ["goods_title", "order_num", "sale_num", "gift_num", "promotion_num",
					"reward_num",
					"pay_price"
				],
				children: [{
						dataIndex: "goods_title",
						title: "商品名称"
					},
					{
						dataIndex: "order_num",
						title: "订单数量"
					},
					{
						dataIndex: "sale_num",
						title: "销售数量"
					},
					{
						dataIndex: "gift_num",
						title: "折扣数量"
					},
					{
						dataIndex: "promotion_num",
						title: "促销数量"
					},
					{
						dataIndex: "reward_num",
						title: "提货奖励数量"
					},
					{
						dataIndex: "pay_price",
						title: "总金额"
					},
				],
			},
			{
				title: "出库单报表",
				value: "custom_order_checkout",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type", "role_id"],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "distributor_type",
						title: "去重",
						options: {},
						treeData: [],
						list: [{
								key: "customer",
								value: "终端"
							},
							{
								key: "wholesale",
								value: "分销"
							}
						]
					},
					{
						type: "text",
						name: "checkout_no",
						title: "出库单号",
					},
					{
						type: "select",
						name: "order_type",
						title: "订单类型",
						options: {},
						list: [{
								key: "1",
								value: "现结订单"
							},
							{
								key: "2",
								value: "预付款订单"
							}
						]
					},
					{
						type: "select",
						name: "checkout_status",
						title: "出库单状态",
						options: {},
						list: [{
								key: "-1",
								value: "已取消"
							},
							{
								key: "0",
								value: "待接单"
							},
							{
								key: "1",
								value: "待配送"
							},
							{
								key: "2",
								value: "待收货"
							},
							{
								key: "3",
								value: "已完成"
							},
						]
					},
					{
						type: "text",
						name: "distributor_search",
						title: "配送商",
						placeholder: "配送商ID/名称"
					},
					{
						type: "range_date",
						name: "create_time",
						title: "拆单时间",
						options: {},
						start: {
							name: "create_start_time"
						},
						end: {
							name: "create_end_time"
						},
					},
					/* {
						type: "range_date",
						name: "checkout_time",
						title: "配送时间",
						options: {},
						start: {
							name: "checkout_start_time"
						},
						end: {
							name: "checkout_end_time"
						},
					},
					{
						type: "range_date",
						name: "receiving_time",
						title: "收货时间",
						options: {},
						start: {
							name: "receiving_start_time"
						},
						end: {
							name: "receiving_end_time"
						},
					}, */
				],
				cate_list: ["人员字段", "客户字段", "出库单字段"],
				selected_field: ["username", "department", "role", "customer_name", "customer_status",
					"customer_type", "order_type", "distributor_name", "checkout_no", "order_no",
					"checkout_status",
					"product_name", "product_num", "product_spec", "gift_name", "gift_num", "gift_spec",
					"promotion_name", "promotion_num", "promotion_spec", "reward_name", "reward_num",
					"reward_spec",
					"pay_price", "create_time"
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "拆单人",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_id",
						title: "下单客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "客户状态",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "客户字段"
					},
					{
						dataIndex: "order_type",
						title: "订单类型",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "distributor_name",
						title: "配送商",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "checkout_no",
						title: "出库单号",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "order_no",
						title: "所属订单编号",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "checkout_status",
						title: "出库单状态",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "product_name",
						title: "下单本品",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "product_num",
						title: "本品数量",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "product_spec",
						title: "本品规格",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "gift_name",
						title: "搭赠品项",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "gift_num",
						title: "搭赠数量",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "gift_spec",
						title: "搭赠规格",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "promotion_name",
						title: "促销品项",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "promotion_num",
						title: "促销数量",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "promotion_spec",
						title: "促销规格",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "reward_name",
						title: "提货奖励品项",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "reward_num",
						title: "提货奖励数量",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "reward_spec",
						title: "提货奖励规格",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "pay_price",
						title: "出库单金额",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "create_time",
						title: "拆单时间",
						cate_name: "出库单字段"
					},
					/* {
						dataIndex: "checkout_time",
						title: "配送时间",
						cate_name: "出库单字段"
					},
					{
						dataIndex: "receiving_time",
						title: "收货时间",
						cate_name: "出库单字段"
					}, */
				],
			},
			{
				title: "商品报表",
				value: "custom_goods_list",
				submit_preprocessing: {
					array_to_string: ["cate_id", "department"]
				},
				form_list: [{
						type: "text",
						name: "goods_search",
						title: "商品名称",
					},
					{
						type: "tree-select",
						name: "cate_id",
						placeholder: "请选择商品分类",
						title: "商品分类",
						options: {},
						treeData: [],
						multiple: true,
						replaceFields: {
							label: "cate_title",
							value: "id",
							children: "children"
						},
					},
					{
						type: "tree-select",
						name: "department",
						title: "所属区域",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "goods_put",
						title: "是否费用投放",
						list: [{
								key: "",
								value: "全部"
							},
							{
								key: "1",
								value: "是"
							},
							{
								key: "0",
								value: "否"
							},
						],
						options: {},
					},
					{
						type: "range_date",
						name: "create_data",
						title: "创建时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					}
				],
				cate_list: [],
				selected_field: ["goods_title", "abbreviation", "goods_cate", "department", "goods_desc",
					"goods_status", "goods_spec", "goods_weight", "goods_price", "max_price", "goods_put",
					"remarks"
				],
				children: [{
						dataIndex: "goods_title",
						title: "商品名称"
					},
					{
						dataIndex: "abbreviation",
						title: "商品简称"
					},
					{
						dataIndex: "goods_cate",
						title: "商品分类"
					},
					{
						dataIndex: "department",
						title: "所属区域"
					},
					{
						dataIndex: "goods_desc",
						title: "货物描述"
					},
					{
						dataIndex: "goods_status",
						title: "状态"
					},
					{
						dataIndex: "goods_spec",
						title: "规格"
					},
					{
						dataIndex: "goods_weight",
						title: "重量"
					},
					{
						dataIndex: "goods_price",
						title: "销售价"
					},
					{
						dataIndex: "max_price",
						title: "预警价"
					},
					{
						dataIndex: "goods_put",
						title: "是否费用投放"
					},
					{
						dataIndex: "remarks",
						title: "备注"
					},
				],
			},
		]
	}, // 订单维度
	{
		icon: "contract",
		title: "合约维度",
		children: [
			{
				title: "费用合约报表",
				value: "custom_cost_contract",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type", "role_id", "launch_type"]
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "text",
						name: "contract_title",
						title: "合约名称",
						options: {}
					},
					{
						type: "select",
						name: "contract_status",
						title: "合约状态",
						list: [{
								key: "-1",
								value: "已过期"
							},
							{
								key: "1",
								value: "期限中"
							},
							{
								key: "-2",
								value: "未开始"
							},
							{
								key: "3",
								value: "已终止"
							},
							{
								key: "2",
								value: "已撤销"
							},
						],
						options: {},
					},
					{
						type: "tree-select",
						name: "launch_type",
						title: "投放类型",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "launch_type",
					// 	title: "投放类型",
					// 	list: [],
					// 	options: {

					// 	}
					// },
					{
						type: "range_date",
						name: "range_date",
						title: "创建时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					},
					{
						type: 'select',
						name: 'class_rebate',
						title: '返利口径',
						list: [{
								key: 'region',
								value: "区域"
							},
							{
								key: 'headquarters',
								value: "总部"
							}
						],
						options: {},
					},
				],
				cate_list: ["人员字段", "客户字段", "合约字段"],
				selected_field: ["username", "department", "role", "customer_name", "customer_status",
					"customer_type", "contract_title", "contract_term", "contract_status", "goods_title",
					"goods_num", "goods_date", "contract_cash", "contract_type", "contract_cycle",
					"class_rebate","require_data","grant_info"
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "下单客户",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "客户状态",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contract_title",
						title: "合约名称",
						cate_name: "合约字段"
					},
					{
						dataIndex: "contract_term",
						title: "合约期限",
						cate_name: "合约字段"
					},
					{
						dataIndex: "contract_cycle",
						title: "合约周期",
						cate_name: "合约字段"
					},
					{
						dataIndex: "contract_status",
						title: "合约状态",
						cate_name: "合约字段"
					},
					{
						dataIndex: "goods_title",
						title: "费用品项",
						cate_name: "合约字段"
					},
					{
						dataIndex: "goods_num",
						title: "品项数量",
						cate_name: "合约字段"
					},
					{
						dataIndex: "goods_date",
						title: "商品时间单位",
						cate_name: "合约字段"
					},
					{
						dataIndex: "contract_cash",
						title: "费用金额",
						cate_name: "合约字段"
					},
					{
						dataIndex: "contract_type",
						title: "投放类型",
						cate_name: "合约字段"
					},
					{
						dataIndex: "class_rebate",
						title: "返利口径",
						cate_name: "合约字段"
					},
					{
						dataIndex: "require_data",
						title: "具体要求",
						cate_name: "合约字段",
						width: '300px',
						scopedSlots: {
							customRender: "require_data"
						}
					},
					{
						dataIndex: "grant_info",
						title: "发放详情",
						cate_name: "合约字段"
					},
				],
			},
			{
				title: "投放报表",
				value: "custom_cost_launch",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type", "role_id", "launch_type"]
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "launch_type",
						title: "投放类型",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "launch_type",
					// 	title: "投放类型",
					// 	list: [],
					// },
					{
						type: "select",
						name: "approval_status",
						title: "审批状态",
						list: [{
								key: "-2",
								value: "已撤回"
							},
							{
								key: "-1",
								value: "已拒绝"
							},
							{
								key: "0",
								value: "待审批"
							},
							{
								key: "1",
								value: "审批中"
							},
							{
								key: "2",
								value: "已同意"
							},
						],
					},
					{
						type: "range_date",
						name: "range_date",
						title: "创建时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					},
					{
						type: 'select',
						name: 'class_rebate',
						title: '返利口径',
						list: [{
								key: 'region',
								value: "区域"
							},
							{
								key: 'headquarters',
								value: "总部"
							}
						],
						options: {},
					},
				],
				cate_list: ["人员字段", "客户字段", "投放字段"],
				selected_field: ["username", "department", "role", "customer_name", "customer_status",
					"customer_type", "launch_type", "distributor_name", "goods_title", "goods_num",
					"goods_date_long",
					"goods_date", "goods_position", "launch_cash", "launch_term", "launch_cycle",
					"approval_status", "launch_desc", "create_time", "class_rebate", "contract_status"
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "客户状态",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "客户字段"
					},
					{
						dataIndex: "launch_type",
						title: "投放类型",
						cate_name: "投放字段"
					},
					{
						dataIndex: "distributor_name",
						title: "配送人",
						cate_name: "投放字段"
					},
					{
						dataIndex: "goods_title",
						title: "费用品项",
						cate_name: "投放字段"
					},
					{
						dataIndex: "goods_num",
						title: "数量",
						cate_name: "投放字段"
					},
					{
						dataIndex: "goods_date_long",
						title: "费用时长",
						cate_name: "投放字段"
					},
					{
						dataIndex: "goods_date",
						title: "费用周期",
						cate_name: "投放字段"
					},
					{
						dataIndex: "goods_position",
						title: "投放位置",
						cate_name: "投放字段"
					},
					{
						dataIndex: "launch_cash",
						title: "投放金额",
						cate_name: "投放字段"
					},
					{
						dataIndex: "launch_term",
						title: "投放期限",
						cate_name: "投放字段"
					},
					{
						dataIndex: "launch_cycle",
						title: "投放周期",
						cate_name: "投放字段"
					},
					{
						dataIndex: "approval_status",
						title: "审批状态",
						cate_name: "投放字段"
					},
					{
						dataIndex: "launch_desc",
						title: "申请事由",
						cate_name: "投放字段"
					},
					{
						dataIndex: "create_time",
						title: "创建时间",
						cate_name: "投放字段"
					},
					{
						dataIndex: "class_rebate",
						title: "返利口径",
						cate_name: "投放字段"
					},
					{
						dataIndex: "contract_status",
						title: "合约状态",
						cate_name: "投放字段"
					},
				],
			},
			{
				title: "发放报表",
				value: "custom_cost_grant",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type", "role_id", "launch_type"]
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},
					{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "role_id",
					// 	title: "岗位职务",
					// 	options: {},
					// 	list: [],
					// },
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "launch_type",
						title: "投放类型",
						options: {},
						treeData: [],
						multiple: true
					},
					// {
					// 	type: "select",
					// 	name: "launch_type",
					// 	title: "投放类型",
					// 	list: [],
					// },
					{
						type: "select",
						name: "approval_status",
						title: "审批状态",
						list: [{
								key: "-2",
								value: "已撤回"
							},
							{
								key: "-1",
								value: "已拒绝"
							},
							{
								key: "0",
								value: "待审批"
							},
							{
								key: "1",
								value: "审批中"
							},
							{
								key: "2",
								value: "已同意"
							},
						],
					},
					{
						type: "select",
						name: "customer_write",
						title: "签收状态",
						list: [{
								key: "0",
								value: "未签收"
							},
							{
								key: "1",
								value: "已签收"
							},
							{
								key: "2",
								value: "异常签收"
							},
						],
					},
					/* {
						type: "range_date",
						name: "range_date",
						title: "创建时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						}
					}, */
					{
						type: "range_date",
						name: "sign_date",
						title: "签收时间",
						options: {},
						start: {
							name: "sign_start_time"
						},
						end: {
							name: "sign_end_time"
						},
					},
					{
						type: "month",
						name: "grant_time",
						title: "发放时间",
						options: {}
					},
					{
						type: 'select',
						name: 'class_rebate',
						title: '返利口径',
						list: [{
								key: 'region',
								value: "区域"
							},
							{
								key: 'headquarters',
								value: "总部"
							}
						],
						options: {},
					},
					{
						type: "select",
						name: "is_filter",
						title: "是否过滤2023年前",
						options: {},
						list: [{
								key: "1",
								value: "是"
							},
							{
								key: "-1",
								value: "否"
							},
						]
					},
				],
				cate_list: ["人员字段", "客户字段", "发放字段"],
				selected_field: ["username", "department", "role", "customer_name", "customer_status",
					"customer_type", "grant_type", "goods_title", "goods_num", "actual_num",
					"goods_position",
					"grant_cash", "distributor_name", "grant_week", "confirm_time", "cost_month",
					"approval_status", "grant_desc", "customer_write", "customer_time", "class_rebate",
					"belong_dealer","grant_user","grant_phone"
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "客户状态",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "客户字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "客户字段"
					},
					{
						dataIndex: "grant_type",
						title: "投放类型",
						cate_name: "发放字段"
					},
					{
						dataIndex: "goods_title",
						title: "费用品项",
						cate_name: "发放字段"
					},
					{
						dataIndex: "goods_num",
						title: "数量",
						cate_name: "发放字段"
					},
					{
						dataIndex: "actual_num",
						title: "实际数量",
						cate_name: "发放字段"
					},
					{
						dataIndex: "goods_position",
						title: "投放位置",
						cate_name: "发放字段"
					},
					{
						dataIndex: "grant_cash",
						title: "金额",
						cate_name: "发放字段"
					},
					{
						dataIndex: "distributor_name",
						title: "配送人",
						cate_name: "发放字段"
					},
					{
						dataIndex: "grant_week",
						title: "投放周",
						cate_name: "发放字段"
					},
					{
						dataIndex: "confirm_time",
						title: "发放时间",
						cate_name: "发放字段"
					},
					{
						dataIndex: "cost_month",
						title: "费用所属月份",
						cate_name: "发放字段"
					},
					{
						dataIndex: "approval_status",
						title: "审批状态",
						cate_name: "发放字段"
					},
					{
						dataIndex: "grant_desc",
						title: "申请事由",
						cate_name: "发放字段"
					},
					{
						dataIndex: "customer_write",
						title: "签收状态",
						cate_name: "发放字段"
					},
					{
						dataIndex: "customer_time",
						title: "签收时间",
						cate_name: "发放字段"
					},
					{
						dataIndex: "contract_require",
						title: "陈列品项",
						cate_name: "发放字段"
					},
					{
						dataIndex: "class_rebate",
						title: "返利口径",
						cate_name: "发放字段"
					},
					{
						dataIndex: "belong_dealer",
						title: "所属经销商",
						cate_name: "发放字段"
					},
					{
						dataIndex: "grant_user",
						title: "签收人",
						cate_name: "发放字段"
					},
					{
						dataIndex: "grant_phone",
						title: "签收人联系方式",
						cate_name: "发放字段"
					},
				],
			},

		]
	}, // 合约维度
	{
		icon: "competing_rate",
		title: "本品铺市率",
		is_more: false,
		children: [{
				title: "按人员信息",
				value: "competing_rate_user",
				submit_preprocessing: {
					array_to_string: ["department", "role_id", "goods_id"],
				},
				form_list: [{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "region_type",
						title: "城乡类型",
						options: {},
						list: [],
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					},

					{
						type: "date",
						name: "end_time",
						title: "日期",
					},
					{
						type: "select",
						name: "goods_id",
						mode: 'multiple',
						title: "品项",
						options: {},
						list: [],
						multiple: true

					},

				],

				cate_list: ["人员字段", "客户字段"],
				selected_field: ["name", "customer_num", "total"],

				children: [{
						dataIndex: "name",
						title: "名称",
						// fixed: 'left',
						cate_name: "人员字段",
						width: '200px',
					},
					{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段",
						width: '150px',
					},

					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						title: "客户数",
						cate_name: "客户字段",
						// fixed: 'left',
						dataIndex: "customer_num",
						width: '200px',
					},
					{
						title: '总数 | 铺市率',
						dataIndex: "total",
						// fixed: 'left',
						width: '200px',
						scopedSlots: {
							customRender: "total"
						}
					},

				],
			},

			{
				title: "按部门",
				value: "competing_rate_dap",
				submit_preprocessing: {
					array_to_string: ["department", "customer_type", "goods_id"],
				},
				form_list: [{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "region_type",
						title: "城乡类型",
						options: {},
						list: [],
					},
					{
						type: "select",
						name: "goods_id",
						title: "品项",
						options: {},
						list: [],
						mode: 'multiple',
						multiple: true
					},
					{
						type: "date",
						name: "end_time",
						title: "日期",
						options: {},

					},

				],
				cate_list: ["人员字段", "客户字段"],
				selected_field: ["name", "customer_num", "total"],

				children: [{
						dataIndex: "name",
						title: "名称",
						// fixed: 'left',
						cate_name: "人员字段",
						width: '200px',
					},

					{
						title: "客户数",
						cate_name: "客户字段",
						// fixed: 'left',
						width: '200px',
						dataIndex: "customer_num",

					},
					{
						title: '总数 | 铺市率',
						dataIndex: "total",
						// fixed: 'left',
						width: '200px',
						scopedSlots: {
							customRender: "total"
						}
					},

				],
			},
            {
				title: "按客户",
				value: "competing_rate_cus",
				submit_preprocessing: {
					array_to_string: ["department", "role_id", "goods_id"],
				},
				form_list: [{
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "region_type",
						title: "城乡类型",
						options: {},
						list: [],
					},
					{
						type: "date",
						name: "end_time",
						title: "日期",
					},
					{
						type: "select",
						name: "goods_id",
						mode: 'multiple',
						title: "品项",
						options: {},
						list: [],
						multiple: true
					},{
						type: "select",
						name: "flag",
						title: "是否铺货",
						options: {},
						list: [{
                            key: "1",
                            value:'是'
                        },{
                            key:'0',
                            value:"否"
                        }],
					},

				],

				cate_list: ["人员字段", "客户信息字段"],
				selected_field: ["username", "department", "role", "customer_name","customer_id", "customer_type",	
                "last_order","last_cost","last_visit"
				],
				children: [{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段"
					},
					{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "sex",
						title: "性别",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段"
					},
					{
						dataIndex: "rule",
						title: "角色",
						cate_name: "人员字段"
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段"
					},
					{
						dataIndex: "phone",
						title: "账号",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段"
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段"
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户信息字段"
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户信息字段"
					},
					{
						dataIndex: "customer_status",
						title: "客户状态",
						cate_name: "客户信息字段"
					},
					{
						dataIndex: "customer_level",
						title: "客户等级",
						cate_name: "客户信息字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户信息字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户信息字段"
					},
					{
						dataIndex: "address",
						title: "门店地址",
						cate_name: "客户信息字段"
					},
					{
						dataIndex: "contact_name",
						title: "联系人",
						cate_name: "客户信息字段"
					},
					{
						dataIndex: "contact_phone",
						title: "联系方式",
						cate_name: "客户信息字段"
					},
					{
						dataIndex: "last_visit",
						title: "最后拜访时间",
						cate_name: "客户信息字段"
					},
					// {
					// 	dataIndex: "visit_frequency",
					// 	title: "拜访频率",
					// 	cate_name: "客户信息字段"
					// },
					// {
					// 	dataIndex: "delivery_name",
					// 	title: "上级配送商",
					// 	cate_name: "客户信息字段"
					// },
					// {
					// 	dataIndex: "dealer_name",
					// 	title: "所属经销商",
					// 	cate_name: "客户信息字段"
					// },
                    {
						dataIndex: "seal_up",
						title: "封存时间",
						cate_name: "客户信息字段"
					},{
						dataIndex: "seal_user",
						title: "封存人",
						cate_name: "客户信息字段"
					},{
						dataIndex: "create_time",
						title: "录入时间",
						cate_name: "客户信息字段"
					},{
						dataIndex: "last_order",
						title: "最后订单时间",
						cate_name: "客户信息字段"
					},{
						dataIndex: "last_cost",
						title: "最后费用时间",
						cate_name: "客户信息字段"
					},
				],
			},
		]
	}, // 通铺率
	{
		icon: "sink_frontline",
		title: "下沉一线",
		is_more: false,
		children: [{
				title: "日统计报表",
				value: "sink_frontline_day",
				submit_preprocessing: {
					array_to_string: ["department",'role_id'],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					}, {
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "range_date",
						name: "range_date",
						title: "日期",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					},
					{
						type: "select",
						name: "status",
						mode: 'multiple',
						title: "状态",
						options: {},
						list: [{
								key: 2,
								value: '白班不合格'
							}, {
								key: 1,
								value: '白班合格'
							},
							{
								key: 4,
								value: '晚班不合格'
							}, {
								key: 3,
								value: '晚班合格'
							},
							{
								key: 0,
								value: '全部'
							}
						],
						multiple: true

					},
					{
						type: "select",
						name: "rule_id",
						mode: 'multiple',
						title: "规则",
						options: {},
						list: [],
						multiple: true

					},

				],
				cate_list: ["人员字段", "下沉字段"],
				selected_field: ["visit_date", "username",'op', 'start_time', 'end_time','total_num','total_day_time','total_night_time'],
				children: [{
						dataIndex: "visit_date",
						title: "日期",
						cate_name: "下沉字段",
						width: '200px',
					},
					{
						dataIndex: "username",
						title: "名称",
						cate_name: "人员字段",
						width: '200px',
					},
					{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段",
						width: '150px',
					},

					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段",
						width: '150px',
					},

					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段",
						width: '150px',
					},


					{
						dataIndex: "is_leave",
						title: "是否请假",
						cate_name: "人员字段",
						width: '150px',
					},

					{
						dataIndex: "is_trip",
						title: "是否出差",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "rule_name",
						title: "规则名称",
						cate_name: "下沉字段",
						width: '150px',
					},

					{
						dataIndex: "start_time",
						title: "开始时间",
						cate_name: "下沉字段",
						width: '150px',
					},
					{
						dataIndex: "end_time",
						title: "最后结束时间",
						cate_name: "下沉字段",
						width: '250px',
					},
					{
						dataIndex: "total_day_time",
						title: "白班执行时长",
						cate_name: "下沉字段",
						width: '250px',
					},
					{
						dataIndex: "total_night_time",
						title: "晚班执行时长",
						cate_name: "下沉字段",
						width: '250px',
					},
					{
						dataIndex: "day_status",
						title: "白班合格",
						cate_name: "下沉字段",
						width: '150px',
					},
					{
						dataIndex: "night_status",
						title: "晚班合格",
						cate_name: "下沉字段",
						width: '150px',
					},
					{
						dataIndex: "total_num",
						title: "总次数",
						cate_name: "下沉字段",
						width: '150px',
					},
					{
						dataIndex: "pass_num",
						title: "合格次数",
						cate_name: "下沉字段",
						width: '150px',
					},


				],
			},

			{
				title: "月统计报表",
				value: "sink_frontline_month",
				submit_preprocessing: {
					array_to_string: ["department",'role_id'],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					}, {
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "month",
						name: "month",
						title: "日期",
						options: {},
					},
					
				],
				cate_list: ["人员字段", "下沉字段"],
				selected_field: ["visit_month", "username", "work_day","need_pass_num","actuality_visit_day_num","actuality_visit_night_num","pass_day_num","status","pass_total_num","pass_night_num",],
				children: [{
						dataIndex: "visit_month",
						title: "月份",
						cate_name: "下沉字段",
						width: '200px',
					},
					{
						dataIndex: "username",
						title: "名称",
						cate_name: "人员字段",
						width: '200px',
					},
					{
						dataIndex: "uid",
						title: "人员ID",
						cate_name: "人员字段",
						width: '150px',
					},

					{
						dataIndex: "department",
						title: "部门",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "role",
						title: "职务",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "is_leader",
						title: "是否部门领导",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "is_admin",
						title: "是否管理员",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "is_leave",
						title: "是否请假",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "is_trip",
						title: "是否出差",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "leave_day",
						title: "请假天数",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "trip_num",
						title: "出差天数",
						cate_name: "人员字段",
						width: '150px',
					},
				
					{
						dataIndex: "work_day",
						title: "工作日天数",
						cate_name: "人员字段",
						width: '150px',
					},

					{
						dataIndex: "need_pass_num",
						title: "应合格天数",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "pass_total_num",
						title: "总合格天数",
						cate_name: "下沉字段",
						width: '150px',
					},
					{
						dataIndex: "pass_day_num",
						title: "合格白班天数",
						cate_name: "下沉字段",
						width: '150px',
					},
					{
						dataIndex: "pass_night_num",
						title: "合格晚班天数",
						cate_name: "下沉字段",
						width: '150px',
					},
					{
						dataIndex: "unneed_day",
						title: "免执行天数",
						cate_name: "人员字段",
						width: '150px',
					},
					{
						dataIndex: "actuality_visit_day_num",
						title: "白班实际执行天数",
						cate_name: "下沉字段",
						width: '250px',
					},
					{
						dataIndex: "actuality_visit_night_num",
						title: "晚班实际执行天数",
						cate_name: "下沉字段",
						width: '250px',
					},
					{
						dataIndex: "status",
						title: "是否合格",
						cate_name: "下沉字段",
						width: '150px',
					},

				],
			},
		]
	}, // 下沉一线
	{
		icon: "sink_frontline",
		title: "其他",
		is_more: false,
		children: [{
				title: "客户销量报表",
				value: "customer_sales_volume",
				submit_preprocessing: {
					array_to_string: ["department",'role_id','customer_type','goods_id'],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					}, {
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "region_type",
						title: "城乡类型",
						options: {},
						list: [],
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "select",
						name: "goods_id",
						mode: 'multiple',
						title: "品项",
						options: {},
						list: [],
						multiple: true
					},
					{
						type: "range_date",
						name: "checkout_date",
						title: "出库时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					},
				],
				cate_list: ["人员字段","客户字段", "销量字段"],
				selected_field: ["username", "role_name",'department_name', 'user_status', 'customer_id','customer_name','customer_type','region_type','customer_status','receipt_number','receipt_goods_number'],
				children: [{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role_name",
						title: "岗位",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department_name",
						title: "所属部门",
						cate_name: "人员字段",
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段",
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "运营状态",
						cate_name: "客户字段"
					},
					{
						dataIndex: "receipt_number",
						title: "回单次数",
						cate_name: "销量字段"
					},
					{
						dataIndex: "receipt_goods_number",
						title: "回单件数",
						cate_name: "销量字段"
					},
				],
			},
	
			{
				title: "客户销量同期对比-分类",
				value: "customer_sales_comparison_cate",
				submit_preprocessing: {
					array_to_string: ["department",'role_id','customer_type','cate_id'],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					}, {
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "region_type",
						title: "城乡类型",
						options: {},
						list: [],
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "tree-select",
						name: "cate_id",
						title: "商品分类",
						options: {},
						treeData: [],
						multiple: true,
						replaceFields: {
							label: "cate_title",
							value: "id",
							children: "children"
						},
					},
					{
						type: "range_date",
						name: "checkout_date",
						title: "出库时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					},
				],
				cate_list: ["人员字段", "客户字段","销量字段"],
				selected_field: ["username", "role_name",'department_name', 'user_status', 'customer_id','customer_name','customer_type','region_type','customer_status','date_range','last_year','difference'],
				children: [{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role_name",
						title: "岗位",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department_name",
						title: "所属部门",
						cate_name: "人员字段",
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段",
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "运营状态",
						cate_name: "客户字段"
					},
				],
			},
			{
				title: "客户销量同期对比-品项",
				value: "customer_sales_comparison_goods",
				submit_preprocessing: {
					array_to_string: ["department",'role_id','customer_type','goods_id'],
				},
				form_list: [{
						type: "text",
						name: "user_search",
						title: "人员信息",
						options: {}
					}, {
						type: "tree-select",
						name: "department",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "tree-select",
						name: "role_id",
						title: "岗位职务",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "text",
						name: "customer_search",
						title: "客户信息",
						options: {}
					},
					{
						type: "select",
						name: "customer_status",
						title: "客户状态",
						options: {},
						list: [{
								key: "1",
								value: "运营中"
							},
							{
								key: "-1",
								value: "封存"
							},
						]
					},
					{
						type: "tree-select",
						name: "customer_type",
						title: "客户类型",
						options: {},
						treeData: [],
						multiple: true
					},
					{
						type: "select",
						name: "region_type",
						title: "城乡类型",
						options: {},
						list: [],
					},
					{
						type: "select",
						name: "customer_level",
						title: "客户等级",
						options: {},
						list: []
					},
					{
						type: "select",
						name: "goods_id",
						mode: 'multiple',
						title: "品项",
						options: {},
						list: [],
						multiple: true
					},
					{
						type: "range_date",
						name: "checkout_date",
						title: "出库时间",
						options: {},
						start: {
							name: "start_time"
						},
						end: {
							name: "end_time"
						},
					},
				],
				cate_list: ["人员字段", "客户字段","销量字段"],
				selected_field: ["username", "role_name",'department_name', 'user_status', 'customer_id','customer_name','customer_type','region_type','customer_status','date_range','last_year','difference'],
				children: [{
						dataIndex: "username",
						title: "姓名",
						cate_name: "人员字段"
					},
					{
						dataIndex: "role_name",
						title: "岗位",
						cate_name: "人员字段"
					},
					{
						dataIndex: "department_name",
						title: "所属部门",
						cate_name: "人员字段",
					},
					{
						dataIndex: "user_status",
						title: "在职状态",
						cate_name: "人员字段",
					},
					{
						dataIndex: "customer_id",
						title: "客户ID",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_name",
						title: "客户名称",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_type",
						title: "客户类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "region_type",
						title: "城乡类型",
						cate_name: "客户字段"
					},
					{
						dataIndex: "customer_status",
						title: "运营状态",
						cate_name: "客户字段"
					},
				],
			},
		]
	}
];

const large = ["place_start_clock_region", "place_end_clock_region", "place_start_clock_time", "place_end_clock_time",
	"order_start_clock_region", "order_end_clock_region", "order_start_clock_time", "order_end_clock_time",
	"goods_title", "goods_position", "product_name", "product_num", "promotion_name", "promotion_num", "gift_name",
	"gift_num", "goods_type", "goods_price",
];
const bigLarge = ["name"];
const short = []

// 对列表宽度进行处理
export default list.map(p_item => {
	p_item.children.map(c_item => {
		c_item.children.map(f_item => {
			if (large.indexOf(f_item.dataIndex) > -1) { // 大杯
				f_item.width = 200;
			} else if (bigLarge.indexOf(f_item.dataIndex) > -1) { // 小杯
				f_item.width = 300;
			} else if (short.indexOf(f_item.dataIndex) > -1) { // 小杯
				f_item.width = 50;
			} else { // 中杯
				f_item.width = 130;
			}
			return f_item
		})
		return c_item
	})
	return p_item;
})